import { mapActions, mapGetters } from 'vuex'
export default {
  name: 'g2-lms',
  components: {
    TextField: () => import(/* webpackChunkName: "icon" */ '@/components/forms/TextField'),
    Button: () => import(/* webpackChunkName: "button" */ '@/components/button/Button'),
    ImageItem: () => import(/* webpackChunkName: "image" */ '@/components/image/ImageItem'),
    Carousel: () => import(/* webpackChunkName: "carousel" */ '@/components/carousel/Carousel')
  },
  data() {
    return {
      fullName: '',
      email: '',
      phoneNumber: '',
      message: '',
      category: 'CORPORATE',
      isError: '',
      carouselBenefitOptions: {
        type: 'loop',
        autoplay: true,
        arrows: false,
        perPage: 1,
        speed: 1000,
        pagination: true,
        interval: 5000,
        gap: '2rem',
        lazyLoad: 'nearby'
      },
      benefitLists: [
        {
          title: 'Suitable for all kinds of learning',
          description: 'Versatile and adaptable, our LMS ensures a seamless integration for all types of learning methods, including Prakerja.',
          image: require('@/assets/images/products/lms-reason-1.png')
        },
        {
          title: 'Quizzes, exams & exercises, we can handle it all',
          description: 'Develop, customize, and conduct all educational assessments on one platform.',
          image: require('@/assets/images/products/lms-reason-2.png')
        },
        {
          title: 'Announcements, messages, comments and more...',
          description: 'Keep everybody in the loop! Easily stay in touch with learners and share updates in an instant.',
          image: require('@/assets/images/products/lms-reason-3.png')
        },
        {
          title: 'Available in both mobile and desktop',
          description: 'Access from home or on the go. Learners can log in anytime, anywhere and from any device!',
          image: require('@/assets/images/products/lms-reason-4.png')
        },
        {
          title: 'Boost sales and exposure via Eduverse',
          description: 'Accelerate exposure and revenue growth for your class, as our LMS is integrated with Eduverse (G2 Sales Platform).',
          image: require('@/assets/images/products/lms-reason-5.png')
        }
      ],
      partnerLists: [
        {
          image: require('@/assets/images/partners/data-academy.png')
        },
        {
          image: require('@/assets/images/partners/accelerice.png')
        },
        {
          image: require('@/assets/images/partners/gtrust.png')
        }
      ]
    }
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'adminGeneral'])
  },
  metaInfo() {
    return {
      title: this.$route.meta.title,
      meta: this.$route.meta.metaTags
    }
  },
  methods: {
    ...mapActions('common', ['sendWhatsApp']),
    sendMessage() {
      this.sendWhatsApp({ number: this.adminGeneral, topic: 'G2%20LMS.' })
    },
    sendEmail() {
      window.open('mailto:corp@g2academy.co', '_self')
    },
    toContactUs() {
      this.$router.push({ name: 'Contact Us' })
    },
    toPageRegisterAlliance() {
      this.$router.push('/register-alliance?assign=LMS')
    }
  }
}
